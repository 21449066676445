import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import portfolio from "../../Assets/Projects/portfolio.PNG";
import securechat from "../../Assets/Projects/securechat.png";
import attendance from "../../Assets/Projects/attendance.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={portfolio}
              isBlog={false}
              title="Sydney Portfolio"
              description="A Portfolio to share some of my projects build with React.js, Node.js, Express.js and Styled with React-Bootstrap and CSS. It is fully responsive."
              ghLink="https://github.com/sydney101"
              demoLink="https://sydneynjoroge.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={securechat}
              isBlog={false}
              title="Secure Chat Application"
              description="It is an Android secure SMS chat application. It was my final university project. It was built with Java and allows users to send encrypted messages using AES 256 bits."
              ghLink="https://github.com/sydney101/Secure-Chat-Application"
              demoLink="https://sydneynjoroge.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={attendance}
              isBlog={false}
              title="Attendance Management System"
              description="A system that allows organizations to manage the time employees check in and out. It was built with PHP, Javascript, CSS, and HTML. I am proud of my work back them becase this project allowed me to get my first formal employment."
              ghLink="https://github.com/sydney101/Attendance-Management-System"
              demoLink="https://sydneynjoroge.com"              
            />
          </Col> 

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
